<template>
  <div id="subpage-content">
    <InformsDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <!-- <v-card id="inf-news-card">
          <div id="inf-news-card-container">
            <img
              height="200"
              width="230"
              :src="getImageUrlbyName('NewsBig1')"
              href=""
              id="inf-news-card-pic"
            />
            <div id="inf-news-card-content">
              <div id="inf-news-cardtitle">
                {{ $t("message.NewsCard1") }}
              </div>
              <div id="inf-news-carddate">{{ $t("message.CNDate1") }}</div>
              <div id="pad" v-html="$t('message.NewsCardText1')"></div>
              <v-btn
                small
                id="inf-btn"
                @click="$router.push('/informs/newspages/290719')"
                >{{ $t("message.More") }}
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card id="inf-news-card">
          <div id="inf-news-card-container">
            <img
              height="200"
              width="230"
              :src="getImageUrlbyName('NewsBig2')"
              href=""
              id="inf-news-card-pic"
            />
            <div id="inf-news-card-content">
              <div id="inf-news-cardtitle">
                {{ $t("message.NewsCard2") }}
              </div>
              <div id="inf-news-carddate">{{ $t("message.CNDate2") }}</div>
              <div id="pad" v-html="$t('message.NewsCardText2')"></div>
              <v-btn
                small
                id="inf-btn"
                @click="$router.push('/informs/newspages/261018')"
                >{{ $t("message.More") }}
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card id="inf-news-card">
          <div id="inf-news-card-container">
            <img
              height="200"
              width="230"
              :src="getImageUrlbyName('NewsBig3')"
              href=""
              id="inf-news-card-pic"
            />
            <div id="inf-news-card-content">
              <div id="inf-news-cardtitle">
                {{ $t("message.NewsCard3") }}
              </div>
              <div id="inf-news-carddate">{{ $t("message.CNDate3") }}</div>
              <div id="pad" v-html="$t('message.NewsCardText3')"></div>
              <v-btn
                small
                id="inf-btn"
                @click="$router.push('/informs/newspages/051018')"
                >{{ $t("message.More") }}
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card id="inf-news-card">
          <div id="inf-news-card-container">
            <img
              height="200"
              width="230"
              :src="getImageUrlbyName('NewsBig4')"
              href=""
              id="inf-news-card-pic"
            />
            <div id="inf-news-card-content">
              <div id="inf-news-cardtitle">
                {{ $t("message.NewsCard4") }}
              </div>
              <div id="inf-news-carddate">{{ $t("message.CNDate4") }}</div>
              <div id="pad" v-html="$t('message.NewsCardText4')"></div>
              <v-btn
                small
                id="inf-btn"
                @click="$router.push('/informs/newspages/200618')"
                >{{ $t("message.More") }}
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card id="inf-news-card">
          <div id="inf-news-card-container">
            <img
              height="90"
              width="230"
              :src="getImageUrlbyName('NewsBig5')"
              href=""
              id="inf-news-card-pic"
            />
            <div id="inf-news-card-content">
              <div id="inf-news-cardtitle">
                {{ $t("message.NewsCard5") }}
              </div>
              <div id="inf-news-carddate">{{ $t("message.CNDate5") }}</div>
              <div id="pad" v-html="$t('message.NewsCardText5')"></div>
              <v-btn
                small
                id="inf-btn"
                @click="$router.push('/informs/newspages/010318')"
                >{{ $t("message.More") }}
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card id="inf-news-card">
          <div id="inf-news-card-container">
            <img
              height="200"
              width="230"
              :src="getImageUrlbyName('NewsBig6')"
              href=""
              id="inf-news-card-pic"
            />
            <div id="inf-news-card-content">
              <div id="inf-news-cardtitle">
                {{ $t("message.NewsCard6") }}
              </div>
              <div id="inf-news-carddate">{{ $t("message.CNDate6") }}</div>
              <div id="pad" v-html="$t('message.NewsCardText6')"></div>
              <v-btn
                small
                id="inf-btn"
                @click="$router.push('/informs/newspages/200516')"
                >{{ $t("message.More") }}
              </v-btn>
            </div>
          </div>
        </v-card>
        <v-card id="inf-news-card">
          <div id="inf-news-card-container">
            <img
              height="200"
              width="230"
              :src="getImageUrlbyName('NewsBig7')"
              href=""
              id="inf-news-card-pic"
            />
            <div id="inf-news-card-content">
              <div id="inf-news-cardtitle">
                {{ $t("message.NewsCard7") }}
              </div>
              <div id="inf-news-carddate">{{ $t("message.CNDate7") }}</div>
              <div id="pad" v-html="$t('message.NewsCardText7')"></div>
              <v-btn
                small
                id="inf-btn"
                @click="$router.push('/informs/newspages/190416')"
                >{{ $t("message.More") }}
              </v-btn>
            </div>
          </div>
        </v-card> -->
        <!--  -->
        <v-col v-for="item in items" :key="item.id" :cols="item.id">
          <v-card id="inf-news-card">
            <div id="inf-news-card-container">
              <img :src="getImageUrl(item.image)" href="" id="inf-news-card-pic" />
              <div id="inf-news-card-content">
                <div id="inf-news-cardtitle">
                  {{ getItemName(item) }}
                </div>
                <div id="inf-news-carddate">{{ getItemDate(item) }}</div>
                <div id="pad" v-html="getItemShorttext(item)"></div>
                <v-btn small id="inf-btn" v-on:click="saveCurrentItem(item)" @click="$router.push('/informs/newspages/anynews')"
                  >{{ $t("message.More") }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
        <!--  -->
      </v-card>
    </div>
  </div>
</template>

<script>
import InformsDrawer from "../../../views/Navigations/Informs/InformsDrawer.vue";
export default {
  components: {
    InformsDrawer,
  },
  data: () => ({
    items: [],
  }),

  async mounted() {
    await this.getNewsblock();
  },
  methods: {
    async getNewsblock() {
      await this.$store.dispatch("getAllNewsblocks");
      this.items = await this.$store.getters.getNewsblocks;
      this.items = this.items.sort((b, a) => new Date(a.date) - new Date(b.date));
      console.log(this.items);
    },
    getImageUrl(item) {
      return "data:image/jpeg;base64," + item;
    },
    getItemName(item) {
      if (this.$i18n.locale === "ru") {
        return item.name1;
      } else if (this.$i18n.locale === "gb") {
        return item.name2;
      }
    },
    getItemDate(item) {
      return item.date.slice(0, 10);
    },
    getItemShorttext(item) {
      if (this.$i18n.locale === "ru") {
        return item.shorttext1;
      } else if (this.$i18n.locale === "gb") {
        return item.shorttext2;
      }
    },
    getItemLongtext(item) {
      if (this.$i18n.locale === "ru") {
        return item.shorttext1;
      } else if (this.$i18n.locale === "gb") {
        return item.shorttext2;
      }
    },
    // checkBtn(item) {
    //   if (localStorage.item) {
    //     this.item = localStorage.item;
    //   }
    //   return item;
    // },
    saveCurrentItem(item) {
      localStorage.itemid = item.id;
      localStorage.itemimage = item.image;
      localStorage.itemname1 = item.name1;
      localStorage.itemname2 = item.name2;
      localStorage.itemname3 = item.name3;
      localStorage.itemname4 = item.name4;
      localStorage.itemname5 = item.name5;
      localStorage.itemdate = item.date.slice(0, 10);
      localStorage.itemlongtext1 = item.longtext1;
      localStorage.itemlongtext2 = item.longtext2;
      localStorage.itemlongtext3 = item.longtext3;
      localStorage.itemlongtext4 = item.longtext4;
      localStorage.itemlongtext5 = item.longtext5;
      console.log(localStorage.itemname1);
      return null;
    },
  },
  watch: {
    itemdata: function (newValue) {
      if (newValue) {
        localStorage.item = newValue;
      }
    },
  },
};
</script>

<style>
/* #news {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 8px;
} */
#inf-news-card {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 250px;
  width: 700px;
}
#inf-news-card-container {
  display: flex;
  justify-content: space-around;
}
#inf-btn {
  margin: 10px;
  display: flex;
  align-self: center;
  width: 25%;
  font: 14px/20px Ubuntu, Arial, sans-serif;
  color: rgb(247, 247, 247);
  background-color: #00569c;
  letter-spacing: 0.01em;
}
#inf-news-card-content {
  margin-left: 5%;
}
#inf-news-card-pic {
  max-height: 150px;
  max-width: 230px;
}
#inf-news-carddate {
  font: 11px/20px Ubuntu, Arial, sans-serif;
  border-left: 1px solid #c4c4c4;
  color: #747474;
  padding-left: 8px;
}
#inf-news-cardtitle {
  padding-top: 4px;
  color: #00569c;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

@media (max-width: 1500px) {
  #content-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 1330px) {
  #content-wrapper {
    flex-direction: column;
  }
  #subpage-content {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 180px;
  }
}
@media (max-width: 1100px) {
  #navcard {
    display: none;
  }
  #inf-news-card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  #inf-news-card {
    height: 400px;
  }
  #inf-news-card-pic {
    display: flex;
  }
}
@media (max-width: 860px) {
  #inf-news-card {
    height: 450px;
    width: 400px;
  }
  #inf-btn {
    width: 30%;
  }
  #rcol-incard {
    padding: 0px;
  }
}
@media (max-width: 480px) {
  #inf-news-card {
    height: 550px;
    width: 300px;
  }
  #inf-btn {
    width: 50%;
  }
  #subpage-content {
    padding-left: 1%;
    padding-right: 1%;
  }
}
</style>
